// en, de, fr, pt
export default {
  en: {
    Dashboard: 'Dashboard',
    Utilisateurs: 'Users',
    Tickets: 'Tickets',
    Messages: 'Messages',
    Compagnies: 'Companies',
    Solutions: 'Solutions',
    Incidents : 'Incidents',
    Tickets_totaux: 'All tickets',
    Tickets_traité:'Processed tickets',
    Tickets_non_traités:'Unprocessed tickets',
    tickets_encours_traitement:'Being processed tickets ',
    En_cours_de_traitement:'Initiate treatment',
    Créer_un_ticket:'New ticket',
    Traiter:'Treaty',
    Traiter1:'Treaty',
    Non_Traité:'Untreated',
    urgency:'Urgency',
    no_urgency:'Not urgency',
    Profil: 'Profile',
    PROFIL: 'PROFILE',
    Nom_Prenons: 'full name',
    mail: 'Email',
    Phone: 'Telephone',
    Compagnie: 'Company',
    Ajouter: 'Add',
    AJOUTER_UN: 'ADD',
    AJOUTER_UNE: 'ADD',
    MODIFICATION_DE_L: 'EDIT',
    UTILISATEUR: 'USER',
    UTILISATEURS: 'Users',
    Modifier: 'Edit',
    Supprimer: 'Delete',
    Action: 'Action',
    Rôle: 'Role',
    Nom: 'Last Name',
    Prénoms: 'First names',
    Soumettre: 'Submit',
    Annuler: 'Cancel',
    enteteDelete: 'do you  want to delete',
    Confirmer :'comfirm',
    SuppressionUser1:'The user is successfully deleted.',
    Suppression:'deletion failed.',
    save_error:'User deletion failed.',
    save_sucess_user:'The user is save successfully.',
    update_user:'The user is changed successfully.',
    last_name_error:  'The last name field is required',
    first_name_error: 'The first names field is required',
    role_error:  'The role field is required',
    email_error: 'The email field is required',
    phoneNumber_error:'The phone number field is required',
    compagnie_error: 'The compagnie field is required',
    SuppressionIncident1:'The category is deleted successfully.',
    description_error:'The description field is required',
    nametype_error:'The incident field is required',
    INCIDENT:'INCIDENT',
    incident:'Incidents',
    incident_update:'The incident is modified successfully.',
    incident_save:'The incident is save successfully.',
    Solution:'Solution',
    Solution_delete: 'The solution is deleted successfully.',
    SOLUTION:'SOLUTION',
    error_solution: 'The solution field is required',
    solution_field_error:'The solution field is required',
    solution_save:'The solution is saved successfully.',
    solution_update:'The solution is modified successfully.',
    Company_delete :'The compagny is deleted successfully.',
    resource_person:'Resource person',
    resourcePerson_error:'The resource person field is required',
    compagny_save: 'The compagny is saved successfully.',
    compagny_update : 'The compagny is modified successfully.',
    COMPAGNIE:'COMPANY',
    non_lu : 'Unread',
    lu :'Read',
    actualiser:'Refresh',
    voir:'View',
    Envoyer_un_mail:'Send email',
    Envoyer:'Send',
    Destinataire:'Recipient',
    créer_un_ticket:'New ticket',
    ticket_error:'Your registration failed.',
    ticket_succes:'The ticket is created successfully.',
    Précédent:'Previous',
    Suivant:'Next',
    Annuler_le_traitement_encours:'Cancel processing in progress',
    Mettre_encours_de_traitement:'Put in process',
    message_error:'Message failed.',
    messsage_envoyer:'The message is sent successfully.',
    message_update:'The message is modified successfully.',
    Déconnexion:'Logout',
    MODIFICATION_DE_MOT_DE_PASSE:'CHANGE PASSWORD',
    Entez_les_infomations_pour_continuer:'Enter the information to continue',
    Mot_de_passe:'Password',
    Confirmation_mot_de_passe:'Password confirmation',
    veuillez_saisir_le_nouveau_mot_de_passe:'Please enter the new password',
    MOT_DE_PASSE_CHANGÉ:'PASSWORD CHANGED',
    ERROR_PASSWORD:'ERROR',
    Modifier_mon_mot_de_passe:'Change password',
    Ouvrez_une_session_pour_commencer_à_utiliser_application:'Log in to start using the application.',
    Se_souvenir_de_moi:'Remember me',
    Mot_de_passe_oublié:'Forgot your password?',
    Connexion:'Login',
    Récupérez_votre_mot_de_passe:'Recover your password',
    recupération:'Please enter your email address and we\'ll send you instructions to reset your password.',
    Changer:'Change',
    password_error:'The password field is required',
    messages:'Messages'
  },

  fr: {
    Dashboard: 'Tableau de bord',
    Utilisateurs: 'Utilisateurs',
    Tickets: 'Tickets',
    Messages: 'Messages',
    Compagnies: 'Compagnies',
    Solutions: 'Solutions',
    Add: 'Ajouter',
    Incidents : 'Incidents',
    Tickets_totaux: 'Tickets totaux',
    Tickets_traité:'Tickets traités',
    Tickets_non_traités:'Tickets non traités',
    En_cours_de_traitement:'en cours de traitement',
    tickets_encours_traitement:' Tickets en traitement ',
    Créer_un_ticket:'Créer un ticket',
    Traiter:'Traité',
    Traiter1:'Traiter',
    Non_Traité:'Non traité',
    urgency:'Urgent',
    no_urgency:'Non urgent',
    Profil: 'Profil',
    PROFIL: 'PROFIL',
    Nom_Prenons: 'Nom & Prénoms',
    mail: 'E-mail',
    Phone: 'Téléphone',
    Compagnie: 'Compagnie',
    Ajouter: 'Ajouter',
    AJOUTER_UN: 'AJOUTER UN',
    AJOUTER_UNE: 'AJOUTER UNE',
    MODIFICATION_DE_L: 'MODIFIIER L\'',
    MODIFICATION_DE: 'MODIFIIER LA',
    UTILISATEUR: 'UTILISATEUR',
    UTILISATEURS: 'Utilisateurs',
    Modifier: 'Modifier',
    Supprimer: 'Supprimer',
    Action: 'Action',
    Rôle: 'Rôle',
    Nom: 'Nom',
    Prénoms: 'Prénoms',
    Soumettre: 'Soumettre',
    Annuler: 'Annuler',
    enteteDelete: 'Confirmer la suppressions?',
    Confirmer :'comfirmer',
    SuppressionUser1:'L\'utilisateur est supprimé avec succès.',
    Suppression:'La suppression a échoué.',
    save_error:'Votre enrégistrement à échouer.',
    save_sucess_user:'L\'utilisateur est enrégistrer avec succès.',
    update_user:'L\'utilisateur est modifié avec succès.',
    last_name_error:  'Le champ nom est obligatoire',
    first_name_error: 'Le champ prénoms est obligatoire',
    role_error:  'Le champ role est obligatoire',
    email_error: 'Le champ e-mail est obligatoire',
    phoneNumber_error:'Le champ téléphone est obligatoire',
    compagnie_error: 'Le champ compagnie est obligatoire',
    Description:'Description',
    SuppressionIncident1:'La categorie est supprimée avec succès.',
    description_error:'Le champ description est obligatoire',
    nametype_error:'Le champ incident est obligatoire',
    INCIDENT:'INCIDENT',
    incident:'Incidents',
    incident_update:'L\'incident est modifiée avec succès.',
    incident_save:'L\'incident est enrégistrée avec succès.',
    Solution:'Solution',
    Solution_delete: 'La solution est supprimée avec succès.',
    error_solution: 'Le champ solution est obligatoire',
    solution_field_error:'Le champ solution est obligatoire',
    solution_save:'La solution est enrégistrée avec succès.',
    solution_update:'La solution est modifiée avec succès.',
    Company_delete :'La compagnie est supprimée avec succès.',
    resource_person:'Personne ressource',
    resourcePerson_error:'Le champ personne ressource est obligatoire',
    compagny_save: 'La compagnie est enrégistrée avec succès.',
    compagny_update : 'La compagnie est modifiée avec succès.',
    COMPAGNIE:'COMPAGNIE',
    non_lu : 'Non lu',
    lu :'Lu',
    actualiser:'Actualiser',
    voir:'Voir',
    Envoyer_un_mail:'Envoyer un mail',
    Envoyer:'Envoyer',
    Destinataire:'Destinataire',
    créer_un_ticket:'Créer un ticket',
    ticket_error:'Votre enrégistrement à échouer.',
    ticket_succes:'Ticket est créé avec succès.',
    Précédent:'Précédent',
    Suivant:'Suivant',
    Annuler_le_traitement_encours:'Annuler le traitement encours',
    Mettre_encours_de_traitement:'Mettre encours de traitement',
    message_error:'Le message à échouer.',
    messsage_envoyer:'Le message est envoyé avec succès.',
    message_update:'Le message est modifié avec succès.',
    Déconnexion:'Déconnexion',
    MODIFICATION_DE_MOT_DE_PASSE:'MODIFICATION DE MOT DE PASSE',
    Entez_les_infomations_pour_continuer:'Entez les infomations pour continuer',
    Mot_de_passe:'Mot de passe',
    Confirmation_mot_de_passe:'Confirmation mot de passe',
    veuillez_saisir_le_nouveau_mot_de_passe:'veuillez saisir le nouveau mot de passe',
    MOT_DE_PASSE_CHANGÉ:'MOT DE PASSE CHANGÉ',
    ERROR_PASSWORD:'ERREUR',
    Modifier_mon_mot_de_passe:'Modifier mon mot de passe',
    Ouvrez_une_session_pour_commencer_à_utiliser_application:'Ouvrez une session pour commencer à utiliser l\'application.',
    Se_souvenir_de_moi:'Se souvenir de moi',
    Mot_de_passe_oublié:'Mot de passe oublié?',
    Connexion:'Connexion',
    Récupérez_votre_mot_de_passe:'Récupérez votre mot de passe',
    recupération:'Veuillez saisir votre adresse e-mail et nous vous enverrons des instructions pour réinitialiser votre mot de passe.',
    Changer:'Changer',
    password_error:'Le champ mot de passe est obligatoire',
    messages:'Messages'

  }
}
